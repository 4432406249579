<template>
    <AdminWrapper>
        <template v-slot:child>
            <div v-if="$store.state.Admin.loadingState"><Spinner /></div>
            <!-- <pnl-email-to :close-modal="() => toggleShowEmailToModal(false)" @on-email-submitted="sendPdf" /> -->
            <!--Balance sheet -->
            <div v-if="!$store.state.Admin.loadingState">
                <div class="w-90 center">
                    <router-link
                        :to="{ name: 'AdminAccountDetails', params: { id: $route.params.id } }"
                        class="pv4-l pv3 mini-spacing"
                    >
                        &lt; Reports
                    </router-link>

                    <div class="flex justify-between items-center pb3 box-border-bottom">
                        <div>
                            <p class="f3 b">Profit & Loss</p>
                        </div>
                        <div class="dropdown-dropdown" style="z-index: -1">
                            <button @click="toggleDropdown" :disabled="state.sending">
                                <span v-if="state.sending"> <font-awesome-icon icon="fas fa-spinner" /> Processing ... </span>
                                <span v-else>Send Report &nbsp; <img src="../../../assets/images/down.svg" alt=""/></span>
                            </button>
                            <div class="options" v-show="state.dropdownOpen">
                                <div class="option" @click="sendPdf(orgData?.email)">Send to self</div>
                                <div class="option" @click="toggleShowEmailToModal(true)">Send to someone</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Balance sheet -->

                <!-- date, comparison & filter  -->
                <div class="w-90 center pt4">
                    <div class="flex justify-between">
                        <div></div>
                        <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem">
                            <div class="flex flex-wrap" style="gap: 1rem">
                                <template v-if="state.comparison === 'period1'">
                                    <input v-model="state.startDate1" type="date" name="" class="input-style" />
                                    <input
                                        @change="changeFirstDate()"
                                        v-model="state.endDate1"
                                        type="date"
                                        name=""
                                        class="input-style"
                                    />
                                </template>

                                <template v-if="state.comparison === 'period2'">
                                    <input v-model="state.startDate2" type="date" name="" class="input-style" />
                                    <input
                                        @change="changeSecondDate()"
                                        v-model="state.endDate2"
                                        type="date"
                                        name=""
                                        class="input-style"
                                    />
                                </template>
                            </div>

                            <select v-model="state.comparison" id="">
                                <option value="period1">Comparison: 1 period</option>
                                <option value="period2">Comparison: 2 period</option>
                            </select>

                            <button class="filter card-box" @click.prevent="handleGetProfitAndLossRecords">
                                <img :src="require('@/assets/images/filter_list.svg')" style="width: 20px" alt="filter" />
                                <span class="pl2 w-50 tl black">Refresh</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- date, comparison & filter  -->

                <!-- report of balance sheet -->
                <div class="w-90 center mt4">
                    <!-- Business Details -->
                    <div class="pa3 box-border">
                        <!-- <OrgData heading="Profit And Loss" /> -->

                        <!-- statement of profit/lose -->
                        <div class="tc pv4">
                            <div class="b" style="color: #132c8c">
                                Statement of profit & loss
                                <!-- for {{ adminUserData?.org }} for the period list -->
                                <p class="b" style="color: #132c8c">
                                    {{ formatDateInLocal(state.startDate1) }} - {{ formatDateInLocal(state.endDate1) }}(1) &
                                </p>
                                <p class="b" style="color: #132c8c">
                                    {{ formatDateInLocal(state.startDate2) }} - {{ formatDateInLocal(state.endDate2) }}(2)
                                </p>
                            </div>
                        </div>

                        <input id="toggle" type="checkbox" @change="toggleZeroItems($event)" checked />
                        <label for="toggle">&nbsp;Hide categories and items with zero</label>
                        <div class="ph2 pt4  ">
                            <div class="scrollmenu w-100 center table-overflow">
                                <table class="w-100 tl mt3 p2" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th class="bb b--black-20 tl items-center">
                                                Accounts
                                            </th>
                                            <th class="bb b--black-20 tl pr3">
                                                {{ getFullYear(state.startDate1) }}
                                            </th>
                                            <th class="bb b--black-20 tl pr3">
                                                {{ getFullYear(state.endDate2) }}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr class="font-w1">
                                            <td
                                                @click="toggleSubTable('revenue')"
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; cursor:pointer"
                                            >
                                                <div class="pr2" style="font-size:14px;">Revenue</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="showRevenueSub ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                />
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.revenue?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.products?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- start of revenue sub table -->
                                        <tr v-if="showRevenueSub">
                                            <td class="pl3">Product</td>
                                            <td>{{ formatAmount(state?.products?.start, $store.state.Settings?.currency) }}</td>
                                            <td>{{ formatAmount(state?.products?.end, $store.state.Settings?.currency) }}</td>
                                        </tr>
                                        <tr v-if="showRevenueSub">
                                            <td class="pl3">Service</td>
                                            <td>{{ formatAmount(state?.services?.start, $store.state.Settings?.currency) }}</td>
                                            <td>{{ formatAmount(state?.services?.end, $store.state.Settings?.currency) }}</td>
                                        </tr>
                                        <tr v-if="showRevenueSub" style="font-weight:600">
                                            <td class="pl3">Total</td>
                                            <td>{{ formatAmount(state?.revenue?.start, $store.state.Settings?.currency) }}</td>
                                            <td>{{ formatAmount(state?.revenue?.end, $store.state.Settings?.currency) }}</td>
                                        </tr>
                                        <!-- end of revenue Sub table -->
                                        <tr class="font-w1">
                                            <td
                                                @click="toggleSubTable('cog')"
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; cursor:pointer"
                                            >
                                                <div class="pr2" style="font-size:14px;">Cost of sales of goods</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="costOfGoods ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                />
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                ({{ formatAmount(state?.total?.start, $store.state.Settings?.currency) }})
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.total?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- start of cost of sales sub table -->
                                        <tr v-if="costOfGoods">
                                            <td class="pl3 bb b--black-20 tablePadding">Purchases</td>
                                            <td class="bb b--black-20 tablePadding tablePadding">
                                                {{ formatAmount(state?.purchases?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding tablePadding">
                                                {{ formatAmount(state?.purchases?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr v-if="costOfGoods">
                                            <td class="pl3 bb b--black-20 tablePadding">Additional Purchase Cost</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.additionalCosts?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.additionalCosts?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr v-if="costOfGoods">
                                            <td class="pl3 bb b--black-20 tablePadding">Opening Inventory</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.openingInventory?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.openingInventory?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr v-if="costOfGoods">
                                            <td class="pl3 bb b--black-20 tablePadding">Closing Inventory</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.closingInventory?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.closingInventory?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr v-if="costOfGoods">
                                            <td class="pl3 bb b--black-20 tablePadding">General Accessories/Raw materials</td>
                                            <td class="bb b--black-20 tablePadding">
                                                ({{
                                                    formatAmount(
                                                        state.inputRawMaterials?.start + state.rawMaterials.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                ({{
                                                    formatAmount(
                                                        state.inputRawMaterials?.end + state.rawMaterials.end,
                                                        $store.state.Settings?.currency
                                                    )
                                                }})
                                            </td>
                                        </tr>
                                        <tr v-if="costOfGoods" style="font-weight:600">
                                            <td class="pl3 bb b--black-20 tablePadding">Total</td>
                                            <td class="bb b--black-20 tablePadding">
                                                ({{ formatAmount(state?.total?.start, $store.state.Settings?.currency) }})
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.total?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr style="font-weight:600">
                                            <td class="">
                                                <div class="pl3 bb b--black-20 tablePadding" v-if="state.grossProfit?.start > 0">
                                                    Gross Profit
                                                </div>
                                                <div v-else>Gross Profit</div>
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                ({{ formatAmount(state?.grossProfit?.start, $store.state.Settings?.currency) }})
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.grossProfit?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- other income -->
                                        <tr class="font-w1">
                                            <td
                                                @click="toggleSubTable('otherIncome')"
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; cursor:pointer"
                                            >
                                                <div class="pr2" style="font-size:14px;">Other Income</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="otherIncome ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                    alt=""
                                                />
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.otherIncome?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.otherIncome?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible:
                                                    state.additionalSalesCost.start == 0 && state.additionalSalesCost.end == 0
                                            }"
                                            style="display: table-row;"
                                        >
                                            <td class="pl3 pv3 pr4 bb b--black-20 tl items-center">Additional Sales Income</td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{
                                                    formatAmount(
                                                        state?.additionalSalesCost?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{
                                                    formatAmount(state?.additionalSalesCost?.end, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible: state.incomeShipping.start == 0 && state.incomeShipping.end == 0
                                            }"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding">Shipping Income</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeShipping?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeShipping?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible: state.incomeDiscount.start == 0 && state.incomeDiscount.end == 0
                                            }"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding">Discount Received</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeDiscount?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeDiscount?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible: state.incomeGift.start == 0 && state.incomeGift.end == 0
                                            }"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding">Gift & Cash donations</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeGift?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeGift?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible: state.serviceCharge.start == 0 && state.serviceCharge.end == 0
                                            }"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding">Service Charges</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.serviceCharge?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.serviceCharge?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherIncome"
                                            :class="{
                                                invisible: state.othersMoneyIn.start == 0 && state.othersMoneyIn.end == 0
                                            }"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding">Other Income</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.othersMoneyIn?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.othersMoneyIn?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr v-if="otherIncome" style="font-weight:600">
                                            <td class="pl3 bb b--black-20 tablePadding">Total</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherIncome?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherIncome?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- operating expense -->
                                        <tr class="font-w1">
                                            <td
                                                @click="toggleSubTable('opsExp')"
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; cursor:pointer"
                                            >
                                                <div class="pr2" style="font-size:14px;">Operating Expense</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="opsExpenses ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                />
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.operatingCost?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.operatingCost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="opsExpenses"
                                            @click="toggleSubTable('distributionSubExp')"
                                            style="font-weight:600; cursor:pointer"
                                        >
                                            <td class="pl3 flex items-center bb b--black-20 tablePadding">
                                                <div class="pr2" style="font-size:14px;">Distribution Expense</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="distributionSubExp ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                    alt=""
                                                />
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.distributionCost?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.distributionCost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- belongs to distribution expense -->
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.transportation.start == 0 && state.transportation.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">
                                                Transportation
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.transportation?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.transportation?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.commission.start == 0 && state.commission.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Commission</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.commission?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.commission?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.fees.start == 0 && state.fees.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Fees</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.fees?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.fees?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.charges.start == 0 && state.charges.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Bank Charge</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.charges?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.charges?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.packaging.start == 0 && state.packaging.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Packaging & Branding</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.packaging?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.packaging?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.rent.start == 0 && state.rent.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Rent</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.rent?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.rent?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.assets.start == 0 && state.assets.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Asset Acquisition</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.assets?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.assets?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.utility.start == 0 && state.utility.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Utility</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.utility?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.utility?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.vat.start == 0 && state.vat.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">VAT Remittance</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.vat?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.vat?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.homeOfficeCost.start == 0 && state.homeOfficeCost.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Home/Office Fixed Cost</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.homeOfficeCost?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.homeOfficeCost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.businessInsurance.start == 0 && state.businessInsurance.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Business Insurance</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.businessInsurance?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.businessInsurance?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible:
                                                    state.businessMealsTravelExpense.start == 0 &&
                                                    state.businessMealsTravelExpense.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Business Meals & Travel Expense</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.businessMealsTravelExpense?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.businessMealsTravelExpense?.end,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible:
                                                    state.furnitureEquipmentMachinery.start == 0 &&
                                                    state.furnitureEquipmentMachinery.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Furniture/Equipment/Machinery</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.furnitureEquipmentMachinery?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.furnitureEquipmentMachinery?.end,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible:
                                                    state.licenseRegistration.start == 0 && state.licenseRegistration.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">License & Registration</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.licenseRegistration?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.licenseRegistration?.end, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible:
                                                    state.professionalBusinessFees.start == 0 &&
                                                    state.professionalBusinessFees.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Professional & Business Fees</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.professionalBusinessFees?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.professionalBusinessFees?.end,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.trainings.start == 0 && state.trainings.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Trainings</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.trainings?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.trainings?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.websiteSoftware.start == 0 && state.websiteSoftware.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">
                                                Website/Software Subscriptions & Licensing
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.websiteSoftware?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.websiteSoftware?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.adsNMarketing.start == 0 && state.adsNMarketing.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Advertising & Marketing</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.adsNMarketing?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.adsNMarketing?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.entertainment.start == 0 && state.entertainment.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Entertainment</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.entertainment?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.entertainment?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.vehicleExpenses.start == 0 && state.vehicleExpenses.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Vehicle Expenses</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.vehicleExpenses?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.vehicleExpenses?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.taxes.start == 0 && state.taxes.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Taxes</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.taxes?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.taxes?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style="font-weight:600"
                                            v-if="distributionSubExp"
                                            :class="{
                                                invisible: state.distributionCost.start == 0 && state.distributionCost.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Total</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.distributionCost?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.distributionCost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- distribution expense sub table ends -->
                                        <tr
                                            v-if="opsExpenses"
                                            @click="toggleSubTable('administrativeSubExp')"
                                            style="font-weight:600; cursor:pointer"
                                        >
                                            <td class="bb pl3 b--black-20 tablePadding flex items-center">
                                                <div class="pr2" style="font-size:14px;">Administration Expense</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="administrativeSubExp ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                    alt=""
                                                />
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.administrativeCost?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.administrativeCost?.end, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                        </tr>
                                        <!-- administrative exp sub table starts -->
                                        <tr
                                            style=""
                                            v-if="administrativeSubExp"
                                            :class="{
                                                invisible: state.salaries.start == 0 && state.salaries.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Salary</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.salaries?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.salaries?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style="font-weight:600"
                                            v-if="administrativeSubExp"
                                            :class="{
                                                invisible:
                                                    state.administrativeCost.start == 0 && state.administrativeCost.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Total</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(
                                                        state?.administrativeCost?.start,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.administrativeCost?.end, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                        </tr>
                                        <!-- administrative exp sub table ends -->
                                        <tr
                                            v-if="opsExpenses"
                                            @click="toggleSubTable('otherExpSub')"
                                            style="font-weight:600; cursor:pointer"
                                        >
                                            <td class="pl3 bb b--black-20 tablePadding flex items-center">
                                                <div class="pr2" style="font-size:14px;">Other Expenses</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="otherExpSub ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                    alt=""
                                                />
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherExpense?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherExpense?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- other exp sub table begins -->
                                        <tr
                                            v-if="otherExpSub"
                                            :class="{
                                                invisible: state.inventoryLost.start == 0 && state.inventoryLost.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Inventory Loss</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.inventoryLost?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.inventoryLost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherExpSub"
                                            :class="{
                                                invisible:
                                                    state.expenseDiscountGiven.start == 0 && state.expenseDiscountGiven.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Discount Given</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.inventoryLost?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.inventoryLost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherExpSub"
                                            :class="{
                                                invisible: state.badDebt.start == 0 && state.badDebt.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Bad Debt</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.badDebt?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.badDebt?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="otherExpSub"
                                            :class="{
                                                invisible: state.others.start == 0 && state.others.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Other Expenses</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.others?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.others?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style="font-weight:600"
                                            v-if="otherExpSub"
                                            :class="{
                                                invisible: state.otherExpense.start == 0 && state.otherExpense.end == 0
                                            }"
                                        >
                                            <td class="pl4 bb b--black-20 tablePadding">Total</td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherExpense?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.otherExpense?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <!-- other exp sub table ends -->
                                        <tr class="font-w1">
                                            <td
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; font-size:14px; cursor:pointer"
                                            >
                                                Net Operating Expense
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.netOperatingCost?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.netOperatingCost?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr class="font-w1">
                                            <td
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; font-size:14px; cursor:pointer"
                                            >
                                                <div v-if="state.operatingProfit.start > 0" class="font-w1">
                                                    Operating Profit
                                                </div>
                                                <div v-else class="font-w1">
                                                    Operating Loss
                                                </div>
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.operatingProfit?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.operatingProfit?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr class="font-w1">
                                            <td
                                                @click="toggleSubTable('finaceCost')"
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; font-size:14px; cursor:pointer"
                                            >
                                                <div class="pr2" style="font-size:14px;">Finance Cost</div>
                                                <img
                                                    id=""
                                                    class="showLayers"
                                                    :class="finaceCost ? 'rotate' : ''"
                                                    :src="require('@/assets/images/arrow.png')"
                                                    width="5"
                                                />
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.netFinanceIncome?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.netFinanceIncome?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style=""
                                            v-if="finaceCost"
                                            :class="{
                                                invisible: state.incomeInterest.start == 0 && state.incomeInterest.end == 0
                                            }"
                                        >
                                            <td class="pl2 bb b--black-20 tablePadding">Interest Income</td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeInterest?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.incomeInterest?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style=""
                                            v-if="finaceCost"
                                            :class="{
                                                invisible: state.interestExpense.start == 0 && state.interestExpense.end == 0
                                            }"
                                        >
                                            <td class="pl2 bb b--black-20 tablePadding">Interest Expense</td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.interestExpense?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.interestExpense?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr
                                            style=""
                                            v-if="finaceCost"
                                            :class="{
                                                invisible: state.netFinanceIncome.start == 0 && state.netFinanceIncome.end == 0
                                            }"
                                        >
                                            <td class="pl2 bb b--black-20 tablePadding">Net Finance Income</td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{
                                                    formatAmount(state?.netFinanceIncome?.start, $store.state.Settings?.currency)
                                                }}
                                            </td>
                                            <td class="pl2 bb b--black-20 tablePadding">
                                                {{ formatAmount(state?.netFinanceIncome?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                        <tr class="font-w1">
                                            <td
                                                class="pv3 bb b--black-20 tl font-w2 flex items-center"
                                                style="padding-right: 4rem; font-size:14px; cursor:pointer"
                                            >
                                                <div v-if="state.profitBeforeTax?.start > 0">
                                                    Profit Before Tax (PBT)
                                                </div>
                                                <div v-else>
                                                    Net Loss
                                                </div>
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state.profitBeforeTax?.start, $store.state.Settings?.currency) }}
                                            </td>
                                            <td class="pv3 pr4 bb b--black-20 tl items-center">
                                                {{ formatAmount(state?.profitBeforeTax?.end, $store.state.Settings?.currency) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="flex justify-between pv4">
                                    <div></div>
                                    <!-- <div class="w-auto-l w-100 flex">
                                        <button @click="downloadPdf" :disabled="state.downloading">
                                            <span v-if="state.downloading">
                                                <font-awesome-icon icon="fas fa-spinner" /> Processing ...
                                            </span>
                                            <span v-else>Export</span>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" box-border-bottom"></div>
                </div>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { computed, reactive, onMounted, watch, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { formatAmount, formatDate, formatDateInLocal, getFullYear, formatDateFilter } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import { useRouter } from 'vue-router'

export default {
    name: 'ProfitAndLoss',
    components: { AdminWrapper, Spinner },

    setup() {
        const store = useStore()
        const reportPnL = computed(() => store?.state?.Admin?.getpnlreport)
        const dataIsLoading = computed(() => store?.state?.Reporting?.dataIsLoading)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()
        const lastYear = year - 1
        const route = useRouter()
        const showRevenueSub = ref(true)
        const costOfGoods = ref(true)
        const otherIncome = ref(true)
        const opsExpenses = ref(true)
        const distributionSubExp = ref(true)
        const administrativeSubExp = ref(true)
        const otherExpSub = ref(true)
        const finaceCost = ref(true)

        const defaultStartDate_1 = new Date(year, 0, 1)
        const defaultEndDate_1 = new Date(year, month, day)

        const defaultStartDate_2 = new Date(lastYear, 0, 1)
        const defaultEndDate_2 = new Date(lastYear, month, day)

        const state = reactive({
            loading: true,
            downloading: false,
            sending: false,
            dropdownOpen: false,
            startDate1: defaultStartDate_1,
            endDate1: defaultEndDate_1,
            startDate2: defaultStartDate_2,
            endDate2: defaultEndDate_2,
            comparison: 'period1',
            products: { start: 0, end: 0 },
            purchases: { start: 0, end: 0 },
            additionalCosts: { start: 0, end: 0 },
            openingInventory: { start: 0, end: 0 },
            closingInventory: { start: 0, end: 0 },
            shipping: { start: 0, end: 0 },
            transportation: { start: 0, end: 0 },
            commission: { start: 0, end: 0 },
            charges: { start: 0, end: 0 },
            ads: { start: 0, end: 0 },
            packaging: { start: 0, end: 0 },
            rent: { start: 0, end: 0 },
            assets: { start: 0, end: 0 },
            utility: { start: 0, end: 0 },
            others: { start: 0, end: 0 },
            interestExpense: { start: 0, end: 0 },
            incomeDiscount: { start: 0, end: 0 },
            incomeShipping: { start: 0, end: 0 },
            incomeGift: { start: 0, end: 0 },
            serviceCharge: { start: 0, end: 0 },
            incomeInterest: { start: 0, end: 0 },
            total: { start: 0, end: 0 },
            salaries: { start: 0, end: 0 },
            grossProfit: { start: 0, end: 0 },
            vat: { start: 0, end: 0 },
            fees: { start: 0, end: 0 },
            inventoryLost: { start: 0, end: 0 },
            distributionCost: { start: 0, end: 0 },
            administrativeCost: { start: 0, end: 0 },
            otherExpense: { start: 0, end: 0 },
            operatingCost: { start: 0, end: 0 },
            netOperatingCost: { start: 0, end: 0 },
            operatingProfit: { start: 0, end: 0 },
            netFinanceIncome: { start: 0, end: 0 },
            profitBeforeTax: { start: 0, end: 0 },
            additionalSalesCost: { start: 0, end: 0 },
            otherIncome: { start: 0, end: 0 },
            services: { start: 0, end: 0 },
            revenue: { start: 0, end: 0 },
            homeOfficeCost: { start: 0, end: 0 },
            officeSupplies: { start: 0, end: 0 },
            businessInsurance: { start: 0, end: 0 },
            businessMealsTravelExpense: { start: 0, end: 0 },
            furnitureEquipmentMachinery: { start: 0, end: 0 },
            inputRawMaterials: { start: 0, end: 0 },
            licenseRegistration: { start: 0, end: 0 },
            professionalBusinessFees: { start: 0, end: 0 },
            trainings: { start: 0, end: 0 },
            websiteSoftware: { start: 0, end: 0 },
            advertisingMarketing: { start: 0, end: 0 },
            adsNMarketing: { start: 0, end: 0 },
            entertainment: { start: 0, end: 0 },
            vehicleExpenses: { start: 0, end: 0 },
            taxes: { start: 0, end: 0 },
            othersMoneyIn: { start: 0, end: 0 },
            expenseDiscountGiven: { start: 0, end: 0 },
            badDebt: { start: 0, end: 0 },
            rawMaterials: { start: 0, end: 0 }
        })

        const handleGetProfitAndLossRecords = () => {
            if (state.startDate1 > state.endDate1 || state.startDate2 > state.endDate2) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            if (!state.startDate1 || !state.endDate1 || !state.startDate2 || !state.endDate2) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Please make sure all dates are selected in both periods',
                    status: false
                })
            }

            const query = `?startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}`

            store.dispatch('Admin/getBusinessPnLReport', { id: route.currentRoute.value.params.id, query: query })
        }

        const downloadPdf = () => {
            // const query = `?startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}`
            // state.downloading = true
            // store.dispatch('Reporting/generateProfitAndLossReportPdf', query).then(response => {
            //     if (response?.data?.error) {
            //         // console.error(response.data.error)
            //     }
            //     const date = formatDate(new Date(), 'DDMMYYYY')
            //     const fileURL = window.URL.createObjectURL(new Blob([response]))
            //     const fileLink = document.createElement('a')
            //     fileLink.href = fileURL
            //     const fileName = `${orgData.value?.org} - ${date}`
            //     fileLink.setAttribute('download', `${fileName}.pdf`)
            //     fileLink.setAttribute('target', '_blank')
            //     document.body.appendChild(fileLink)
            //     fileLink.click()
            //     fileLink.remove()
            //     state.downloading = false
            // })
        }

        const sendPdf = toEmail => {
            // hideDropdown()
            // const query = `?startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}&email=${toEmail}`
            // state.sending = true
            // store.dispatch('Reporting/generateProfitAndLossReportPdfAndSend', query).then(response => {
            //     state.sending = false
            // })
        }

        const toggleSubTable = name => {
            if (name === 'revenue') {
                showRevenueSub.value = !showRevenueSub.value
            }
            if (name === 'cog') {
                costOfGoods.value = !costOfGoods.value
            }
            if (name === 'otherIncome') {
                otherIncome.value = !otherIncome.value
            }
            if (name === 'opsExp') {
                opsExpenses.value = !opsExpenses.value
            }
            if (name === 'distributionSubExp') {
                distributionSubExp.value = !distributionSubExp.value
            }
            if (name === 'administrativeSubExp') {
                administrativeSubExp.value = !administrativeSubExp.value
            }
            if (name === 'otherExpSub') {
                otherExpSub.value = !otherExpSub.value
            }
            if (name === 'finaceCost') {
                finaceCost.value = !finaceCost.value
            }
        }

        const refreshData = newData => {
            state.loading = dataIsLoading.value
            state.products.start = newData.products.start
            state.products.end = newData.products.end
            state.purchases.start = newData.purchases.start
            state.purchases.end = newData.purchases.end
            state.additionalCosts.start = newData.additionalCosts.start
            state.additionalCosts.end = newData.additionalCosts.end
            state.openingInventory.start = newData.openingInventory.start
            state.openingInventory.end = newData.openingInventory.end
            state.closingInventory.start = newData.closingInventory.start
            state.closingInventory.end = newData.closingInventory.end
            state.shipping.start = newData.shipping.start
            state.shipping.end = newData.shipping.end
            state.transportation.start = newData.transportation.start
            state.transportation.end = newData.transportation.end
            state.commission.start = newData.commission.start
            state.commission.end = newData.commission.end
            state.charges.start = newData.charges.start
            state.charges.end = newData.charges.end
            state.ads.start = newData.ads.start
            state.ads.end = newData.ads.end
            state.packaging.start = newData.packaging.start
            state.packaging.end = newData.packaging.end
            state.rent.start = newData.rent.start
            state.rent.end = newData.rent.end
            state.assets.start = newData.assets.start
            state.assets.end = newData.assets.end
            state.utility.start = newData.utility.start
            state.utility.end = newData.utility.end
            state.others.start = newData.others.start
            state.others.end = newData.others.end
            state.interestExpense.start = newData.interestExpense.start
            state.interestExpense.end = newData.interestExpense.end
            state.incomeShipping.start = newData.shipping.start
            state.incomeShipping.end = newData.shipping.end
            state.incomeGift.start = newData.incomeGift.start
            state.incomeGift.end = newData.incomeGift.end
            state.serviceCharge.start = newData.serviceCharge.start
            state.serviceCharge.end = newData.serviceCharge.end
            state.salaries.start = newData.salaries.start
            state.salaries.end = newData.salaries.end
            state.incomeDiscount.start = newData.incomeDiscount.start
            state.incomeDiscount.end = newData.incomeDiscount.end
            state.incomeInterest.start = newData.incomeInterest.start
            state.incomeInterest.end = newData.incomeInterest.end
            state.vat.end = newData.vat.end
            state.vat.start = newData.vat.start
            state.fees.start = newData.fees.start
            state.fees.end = newData.fees.end
            state.additionalSalesCost.start = newData.additionalSalesCost.start
            state.additionalSalesCost.end = newData.additionalSalesCost.end
            state.inventoryLost.start = newData.inventoryLost.start
            state.inventoryLost.end = newData.inventoryLost.end
            state.services.start = newData.services.start
            state.services.end = newData.services.end
            state.expenseDiscountGiven.start = newData.expenseDiscountGiven.start
            state.expenseDiscountGiven.end = newData.expenseDiscountGiven.end
            state.badDebt.start = newData.badDebt.start
            state.badDebt.end = newData.badDebt.end
            state.rawMaterials.start = newData.rawMaterials.start
            state.rawMaterials.end = newData.rawMaterials.end

            state.othersMoneyIn.start = newData.othersMoneyIn.start
            state.othersMoneyIn.end = newData.othersMoneyIn.end

            // homeOfficeCost
            state.homeOfficeCost.start = newData.homeOfficeCost.start
            state.homeOfficeCost.end = newData.homeOfficeCost.end

            //officeSupplies
            state.officeSupplies.start = newData.officeSupplies.start
            state.officeSupplies.end = newData.officeSupplies.end

            //businessInsurance
            state.businessInsurance.start = newData.businessInsurance.start
            state.businessInsurance.end = newData.businessInsurance.end

            //businessMealsTravelExpense
            state.businessMealsTravelExpense.start = newData.businessMealsTravelExpense.start
            state.businessMealsTravelExpense.end = newData.businessMealsTravelExpense.end

            //furnitureEquipmentMachinery
            state.furnitureEquipmentMachinery.start = newData.furnitureEquipmentMachinery.start
            state.furnitureEquipmentMachinery.end = newData.furnitureEquipmentMachinery.end

            //inputRawMaterials
            state.inputRawMaterials.start = newData.inputRawMaterials.start
            state.inputRawMaterials.end = newData.inputRawMaterials.end

            //licenseRegistration
            state.licenseRegistration.start = newData.licenseRegistration.start
            state.licenseRegistration.end = newData.licenseRegistration.end

            //professionalBusinessFees
            state.professionalBusinessFees.start = newData.professionalBusinessFees.start
            state.professionalBusinessFees.end = newData.professionalBusinessFees.end

            //trainings
            state.trainings.start = newData.trainings.start
            state.trainings.end = newData.trainings.end

            //websiteSoftware
            state.websiteSoftware.start = newData.websiteSoftware.start
            state.websiteSoftware.end = newData.websiteSoftware.end

            //advertisingMarketing
            state.advertisingMarketing.start = newData.advertisingMarketing.start
            state.advertisingMarketing.end = newData.advertisingMarketing.end

            state.adsNMarketing.start = parseFloat(newData.ads.start) + parseFloat(newData.advertisingMarketing.start)
            state.adsNMarketing.end = parseFloat(newData.ads.end) + parseFloat(newData.advertisingMarketing.end)

            //entertainment
            state.entertainment.start = newData.entertainment.start
            state.entertainment.end = newData.entertainment.end

            //vehicleExpenses
            state.vehicleExpenses.start = newData.vehicleExpenses.start
            state.vehicleExpenses.end = newData.vehicleExpenses.end

            //taxes
            state.taxes.start = newData.taxes.start
            state.taxes.end = newData.taxes.end

            // revenue products + services
            state.revenue.start = newData.products.start + newData.services.start
            state.revenue.end = newData.products.end + newData.services.end

            // Purchase + additional cost + opening inventory - closing inventory

            state.total = {
                start:
                    newData.purchases.start +
                    newData.additionalCosts.start +
                    newData.openingInventory.start -
                    newData.closingInventory.start +
                    newData.rawMaterials.start +
                    newData.inputRawMaterials.start,
                end:
                    newData.purchases.end +
                    newData.additionalCosts.end +
                    newData.openingInventory.end -
                    newData.closingInventory.end +
                    newData.rawMaterials.end +
                    newData.inputRawMaterials.start
            }

            // shipping + gift + discount + others + additionalSalesCost
            state.otherIncome = {
                start:
                    parseFloat(newData.incomeShipping.start) +
                    parseFloat(newData.incomeGift.start) +
                    parseFloat(newData.serviceCharge.start) +
                    parseFloat(newData.othersMoneyIn.start) +
                    parseFloat(newData.incomeDiscount.start) +
                    parseFloat(newData.additionalSalesCost.start),
                end:
                    parseFloat(newData.incomeShipping.end) +
                    parseFloat(newData.incomeGift.end) +
                    parseFloat(newData.serviceCharge.end) +
                    parseFloat(newData.othersMoneyIn.end) +
                    parseFloat(newData.incomeDiscount.end) +
                    parseFloat(newData.additionalSalesCost.end)
            }
            // Gross profit product - total cost

            state.grossProfit = {
                start:
                    newData.products.start +
                    newData.services.start -
                    (newData.purchases.start +
                        newData.additionalCosts.start +
                        newData.openingInventory.start -
                        newData.closingInventory.start +
                        newData.rawMaterials.start +
                        newData.inputRawMaterials.start),
                end:
                    newData.products.end +
                    newData.services.end -
                    (newData.purchases.end +
                        newData.additionalCosts.end +
                        newData.openingInventory.end -
                        newData.closingInventory.end +
                        newData.rawMaterials.end +
                        newData.inputRawMaterials.end)
            }

            state.distributionCost.start =
                parseFloat(newData.utility.start) +
                // parseFloat(newData.assets.start) +
                parseFloat(newData.rent.start) +
                parseFloat(newData.packaging.start) +
                parseFloat(newData.ads.start) +
                parseFloat(newData.charges.start) +
                parseFloat(newData.commission.start) +
                parseFloat(newData.transportation.start) +
                // parseFloat(newData.vat.start) +
                parseFloat(newData.fees.start) +
                parseFloat(newData.homeOfficeCost.start) +
                parseFloat(newData.officeSupplies.start) +
                parseFloat(newData.businessInsurance.start) +
                parseFloat(newData.taxes.start) +
                parseFloat(newData.vehicleExpenses.start) +
                parseFloat(newData.entertainment.start) +
                parseFloat(newData.advertisingMarketing.start) +
                parseFloat(newData.websiteSoftware.start) +
                parseFloat(newData.trainings.start) +
                parseFloat(newData.professionalBusinessFees.start) +
                parseFloat(newData.licenseRegistration.start) +
                // parseFloat(newData.inputRawMaterials.start) +
                parseFloat(newData.furnitureEquipmentMachinery.start) +
                parseFloat(newData.businessMealsTravelExpense.start)

            state.distributionCost.end =
                parseFloat(newData.utility.end) +
                // parseFloat(newData.assets.end) +
                parseFloat(newData.rent.end) +
                parseFloat(newData.packaging.end) +
                parseFloat(newData.ads.end) +
                parseFloat(newData.charges.end) +
                parseFloat(newData.commission.end) +
                parseFloat(newData.transportation.end) +
                // parseFloat(newData.vat.end) +
                parseFloat(newData.fees.end) +
                parseFloat(newData.homeOfficeCost.end) +
                parseFloat(newData.officeSupplies.end) +
                parseFloat(newData.businessInsurance.end) +
                parseFloat(newData.taxes.end) +
                parseFloat(newData.vehicleExpenses.end) +
                parseFloat(newData.entertainment.end) +
                parseFloat(newData.advertisingMarketing.end) +
                parseFloat(newData.websiteSoftware.end) +
                parseFloat(newData.trainings.end) +
                parseFloat(newData.professionalBusinessFees.end) +
                parseFloat(newData.licenseRegistration.end) +
                // parseFloat(newData.inputRawMaterials.end) +
                parseFloat(newData.furnitureEquipmentMachinery.end) +
                parseFloat(newData.businessMealsTravelExpense.end)

            // Administrative Cost
            ;(state.administrativeCost.start = parseFloat(newData.salaries.start)),
                (state.administrativeCost.end = parseFloat(newData.salaries.end))

            // Other expense
            state.otherExpense.start =
                parseFloat(newData.inventoryLost.start) +
                parseFloat(state.others.start) +
                parseFloat(newData.expenseDiscountGiven.start) +
                parseFloat(newData.badDebt.start)
            state.otherExpense.end =
                parseFloat(newData.inventoryLost.end) +
                parseFloat(state.others.end) +
                parseFloat(newData.expenseDiscountGiven.end) +
                parseFloat(newData.badDebt.end)

            // Distribution cost + Administrative  Cost + Other Expense + Net Impairment - Other Income

            state.operatingCost = {
                start:
                    parseFloat(state.distributionCost.start) +
                    parseFloat(state.administrativeCost.start) +
                    parseFloat(state.otherExpense.start),
                end:
                    parseFloat(state.distributionCost.end) +
                    parseFloat(state.administrativeCost.end) +
                    parseFloat(state.otherExpense.end)
            }

            //Net operating cost = gross profit + other income - Operating cost

            state.netOperatingCost = {
                start:
                    // parseFloat(state.grossProfit.start) +
                    parseFloat(state.otherIncome.start) - parseFloat(state.operatingCost.start),
                end:
                    // parseFloat(state.grossProfit.end) +
                    parseFloat(state.otherIncome.end) - parseFloat(state.operatingCost.end)
            }

            // Gross Profit - Net Operating Cost + otherIncome
            state.operatingProfit = {
                // start: parseFloat(state.grossProfit.start) - parseFloat(state.netOperatingCost.start),
                start:
                    parseFloat(state.grossProfit.start) +
                    parseFloat(state.otherIncome.start) -
                    parseFloat(state.operatingCost.start),
                // end: parseFloat(state.grossProfit.end) - parseFloat(state.netOperatingCost.end),
                end: parseFloat(state.grossProfit.end) + parseFloat(state.otherIncome.end) - parseFloat(state.operatingCost.end)
            }

            // Interest Income - Interest Expense
            state.netFinanceIncome = {
                start: parseFloat(newData.incomeInterest.start) - parseFloat(newData.interestExpense.start),
                end: parseFloat(newData.incomeInterest.end) - parseFloat(newData.interestExpense.end)
            }

            // Operating Profit + Net Finance Income
            state.profitBeforeTax = {
                start: parseFloat(state.operatingProfit.start) + parseFloat(state.netFinanceIncome.start),
                end: parseFloat(state.operatingProfit.end) + parseFloat(state.netFinanceIncome.end)
            }
        }

        const toggleShowEmailToModal = status => {
            store.dispatch('Reporting/showEmailToModal', status)
            hideDropdown()
        }

        const toggleDropdown = () => {
            state.dropdownOpen = !state.dropdownOpen
        }

        const showDropdown = () => {
            state.dropdownOpen = true
        }

        const hideDropdown = () => {
            state.dropdownOpen = false
        }

        const changeFirstDate = () => {
            // console.log('start', state.startDate1)
            state.startDate1 = formatDateFilter(state.startDate1)
            state.endDate1 = formatDateFilter(state.endDate1)
            state.startDate2 = formatDateFilter(state.startDate2)
            state.endDate2 = formatDateFilter(state.endDate2)

            const query = `startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}`

            store.dispatch('Admin/getBusinessPnLReport', { id: route.currentRoute.value.params.id, query: query })
        }

        const changeSecondDate = () => {
            state.startDate1 = formatDateFilter(state.startDate1)
            state.endDate1 = formatDateFilter(state.endDate1)
            state.startDate2 = formatDateFilter(state.startDate2)
            state.endDate2 = formatDateFilter(state.endDate2)

            const query = `startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}`

            store.dispatch('Admin/getBusinessPnLReport', { id: route.currentRoute.value.params.id, query: query })
        }

        // watchEffect(() => {
        //     if (state.startDate1) {
        //         console.log('hiiii', state.startDate1)
        //     }
        // })

        watch(
            () => reportPnL.value,
            () => {
                refreshData(reportPnL.value)
            }
        )

        watch(
            () => dataIsLoading.value,
            () => {
                if (dataIsLoading.value) {
                    setLoading(true)
                } else {
                    setLoading(false)
                }
            }
        )

        const setLoading = value => {
            state.loading = value
        }

        onMounted(() => {
            state.startDate1 = formatDateFilter(state.startDate1)
            state.endDate1 = formatDateFilter(state.endDate1)
            state.startDate2 = formatDateFilter(state.startDate2)
            state.endDate2 = formatDateFilter(state.endDate2)

            const query = `startDate1=${state.startDate1}&endDate1=${state.endDate1}&startDate2=${state.startDate2}&endDate2=${state.endDate2}`

            store.dispatch('Admin/getBusinessPnLReport', { id: route.currentRoute.value.params.id, query: query })
        })

        return {
            formatAmount,
            formatDateInLocal,
            getFullYear,
            state,
            reportPnL,
            refreshData,
            handleGetProfitAndLossRecords,
            downloadPdf,
            sendPdf,
            orgData,
            formatDateFilter,
            toggleDropdown,
            showDropdown,
            hideDropdown,
            toggleShowEmailToModal,
            toggleSubTable,
            costOfGoods,
            otherIncome,
            opsExpenses,
            distributionSubExp,
            administrativeSubExp,
            otherExpSub,
            finaceCost,
            showRevenueSub,
            changeFirstDate,
            changeSecondDate
        }
    },

    methods: {
        toggleZeroItems: e => {
            const nodes = document.querySelectorAll('.invisible')
            let nodeDisplay = 'table-row'
            if (e.target.checked === true) {
                nodeDisplay = 'none'
            }
            // else {
            //     nodeDisplay = 'flex'
            // }

            nodes.forEach(node => {
                node.style.display = nodeDisplay
            })
        }
    }
}
</script>

<style scoped>
.plml2 {
    margin: 0 1rem !important;
}
.Productml {
    margin-right: 1rem;
}

span {
    padding: 20px;
    font-family: 'manrope' !important;
}
.justify {
    padding-left: 40px !important;
    font-family: 'manrope' !important;
}

.tab,
.tab * {
    font-family: arial, sans-serif;
    box-sizing: border-box;
    font-family: 'manrope' !important;
}
.tab {
    max-width: 100%;
    font-family: 'manrope' !important;
}

.tab input {
    display: none;
}

.tab label {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    padding-right: 20px !important;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
    font-family: 'manrope' !important;
}

.tab .content {
    background: #fff;
    overflow: hidden;
    transition: max-height 0.1s;
    max-height: 100%;
    font-family: 'manrope' !important;
}
.tab .content div {
    padding: 8px 10px;
}

.tab input:checked ~ .content {
    max-height: 0;
    font-family: 'manrope' !important;
}

.tab label::after {
    content: '\25b6';
    position: absolute;
    left: 10px;
    top: 10px;
    transition: all 0.4s;
    font-family: 'manrope' !important;
}

.tab input:checked ~ label::after {
    display: block;
    transform: rotate(90deg);
    font-family: 'manrope' !important;
}

.project-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

#item1 {
    grid-column: 1 / 2;
    overflow-wrap: initial;
    font-family: 'manrope' !important;
}

#item2 {
    text-align: end;
}

#item3 {
    text-align: end;
    padding-right: 20px;
    font-family: 'manrope' !important;
}

.table-overflow {
    overflow: auto;
    font-family: 'manrope' !important;
}

@media only screen and (max-width: 980px) {
    .ph2 {
        padding: 0px !important;
        font-family: 'manrope' !important;
    }

    .table-width {
        overflow: auto;
        font-family: 'manrope' !important;
    }

    .text2 {
        text-align: right !important;
        font-family: 'manrope' !important;
    }

    .tab .content div {
        padding: 8px 10px;
        font-family: 'manrope' !important;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.fa-spinner {
    animation: spinner 1s linear infinite;
}

/* Entity filter dropdown */
.dropdown-dropdown {
    cursor: pointer;
    position: relative;
    /* left: 50%; */
    /* top: 50%; */
    /* transform: translate(-50%, -70px); */
    /* min-width: 350px; */
    /* height: 40px; */
    z-index: 1;
}

/* Selected */
.dropdown-btn {
    position: relative;
    z-index: 2;
    /* display: block; */
    width: 100%;
    height: 30px;
    padding: 0 10px;
    /* background: #054641; */
    /* border-radius: 10px; */
    font: 1.25rem/40px 'Ubuntu', Helvetica, Arial, sans-serif;
    /* text-shadow: 2px 2px 0px #000; */
    /* color: #fff;   */
}

/* Arrow */
.dropdown-btn:after {
    opacity: 0.5;
    display: inline-block;
    margin-left: 10px;
    content: '▼';
}

/* Hover state */
.dropdown-btn:hover:after {
    opacity: 1;
}

/* Options wrapper (toggled by isOpen) */
.options {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    margin-top: 3px;
    background: #fff;
    /* border-radius: 10px; */
    border: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left;
}

/* Option */
.option {
    padding: 5px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

/* Hover state */
.option:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Reset last child for a nice layout */
.option:last-child {
    border-bottom: none;
}

.invisible {
    display: none;
}

.showLayers {
    transition: transform 0.3s;
    z-index: -1;
}

.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.tablePadding {
    padding-top: 4px;
    padding-bottom: 4px;
}

.fixreport {
    position: fixed;
    position: fixed;
    width: 76%;
    height: 200px;
}
</style>
